<template>
  <div class="px-4 py-3 bg--grey">
    <div class="form--group" :class="{ 'has-error': validation.hasError('purchasePrice') }">
      <div class="col-form-label">{{ $t('mortgage.purchasePrice') }}</div>
      <div>
        <cleave
          class="basic--input mt-2"
          id="purchasePrice"
          name="purchasePrice"
          :placeholder="$t('mortgage.purchasePrice')"
          v-model="purchasePrice"
          :options="cleaveOptionsPrice"
        ></cleave>
        <div class="val-error mt-2" v-if="validation.hasError('purchasePrice')">
          {{ validation.firstError('purchasePrice') }}
        </div>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('downPayment') }">
      <div class="col-form-label">{{ $t('mortgage.downPayment') }}</div>
      <div>
        <cleave
          class="basic--input mt-2"
          id="downPayment"
          name="downPayment"
          :placeholder="$t('mortgage.downPayment')"
          v-model="downPayment"
          :options="cleaveOptionsPrice"
        ></cleave>
        <div class="val-error mt-2" v-if="validation.hasError('downPayment')">
          {{ validation.firstError('downPayment') }}
        </div>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('preferredBank') }">
      <div class="col-form-label">{{ $t('mortgage.bankPreference') }}</div>
      <div>
        <multiselect
          v-model="preferredBank"
          select-label=""
          deselect-label=""
          name="employmentStatus"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="true"
          :options="allBanks"
          :placeholder="$t('mortgage.placeholder.preferredBank')"
          @input="onSelectBank"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('preferredBank')">{{
          validation.firstError('preferredBank')
        }}</span>
      </div>
    </div>
    <bank-info :bank="preferredBank"></bank-info>
    <div
      class="form--group"
      :class="{ 'has-error': validation.hasError('program') }"
      v-if="preferredBank && preferredBank.programs"
    >
      <div class="col-form-label">{{ $t('mortgage.program') }}</div>
      <div>
        <multiselect
          v-model="program"
          select-label=""
          deselect-label=""
          name="program"
          label="title"
          :searchable="true"
          :close-on-select="true"
          :show-labels="true"
          :options="preferredBank.programs"
          :placeholder="$t('mortgage.placeholder.program')"
          @input="onSelectProgram"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('program')">{{
          validation.firstError('program')
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form--group" :class="{ 'has-error': validation.hasError('interestRateFixed') }">
          <label for="interestRateFixed">{{ $t('mortgage.interestRateFixed') }}</label>
          <cleave
            class="basic--input mt-2"
            id="interestRateFixed"
            name="interestRateFixed"
            type="text"
            :placeholder="$t('mortgage.interestRateFixed')"
            v-model="interestRateFixed"
            :options="cleaveOptionPercentage"
          />
          <div class="val-error mt-2" v-if="validation.hasError('interestRateFixed')">
            {{ validation.firstError('interestRateFixed') }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form--group" :class="{ 'has-error': validation.hasError('tenureInYearFixed') }">
          <label for="tenureInYearFixed">{{ $t('mortgage.loanTenureFixed') }}</label>
          <cleave
            class="basic--input mt-2"
            id="tenureInYearFixed"
            name="tenureInYearFixed"
            type="text"
            :placeholder="$t('mortgage.loanTenureFixed')"
            v-model="tenureInYearFixed"
            :options="cleaveOptionPercentage"
          />
          <div class="val-error mt-2" v-if="validation.hasError('tenureInYearFixed')">
            {{ validation.firstError('tenureInYearFixed') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form--group" :class="{ 'has-error': validation.hasError('interestRate') }">
          <label for="interestRate">{{ $t('mortgage.interestRateFloat') }}</label>
          <input
            class="basic--input mt-2"
            id="interestRate"
            name="interestRate"
            type="text"
            :placeholder="$t('mortgage.interestRateFloat')"
            v-model="interestRate"
            :options="cleaveOptionPercentage"
          />
          <div class="val-error mt-2" v-if="validation.hasError('interestRate')">
            {{ validation.firstError('interestRate') }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form--group" :class="{ 'has-error': validation.hasError('tenureInYear') }">
          <label for="tenureInYear">{{ $t('mortgage.loanTenureTotal') }}</label>
          <input
            class="basic--input mt-2"
            id="tenureInYear"
            name="tenureInYear"
            type="text"
            :placeholder="$t('mortgage.loanTenureTotal')"
            v-model="tenureInYear"
            :options="cleaveOptionPercentage"
          />
          <div class="val-error mt-2" v-if="validation.hasError('tenureInYear')">
            {{ validation.firstError('tenureInYear') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn btn-primary mt-4"
      style="width: fit-content; margin: auto"
      @click="calculateMortgage"
    >
      {{ $t('mortgage.calculateMortgage') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');
import Multiselect from 'vue-multiselect';
import HelperMixin from '@/mixins/helpers';
const BankInfo = () => import('@/components/mortgage/bank-info');

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  mixins: [HelperMixin],
  components: {
    Cleave,
    Multiselect,
    BankInfo,
  },
  computed: {
    ...mapGetters({
      allPrograms: 'v2/mortgage/allPrograms',
      allBanks: 'v2/mortgage/allBanks',
    }),
    ...mapState({
      defaultDP: (state) => state.v2.mortgage.defaultDP,
    }),
    purchasePrice: {
      get() {
        return this.purchasePriceValue;
      },
      set(val) {
        this.purchasePriceValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
      },
    },
    downPayment: {
      get() {
        return this.downPaymentValue;
      },
      set(val) {
        this.downPaymentValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
      },
    },
  },
  data() {
    return {
      cleaveOptionsPrice: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
      },
      cleaveOptionPercentage: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 2,
        numeralDecimalScale: 2,
      },
      purchasePriceValue: 0,
      downPaymentValue: 0,
      interestRate: 12,
      interestRateFixed: 0,
      tenureInYear: 20,
      tenureInYearFixed: 0,
      program: null,
      loanAmountValue: 0,
      preferredBank: null,
    };
  },
  validators: {
    purchasePrice(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.purchasePrice.required'))
        .custom(() => {
          if (this.purchasePriceValue <= 0) {
            return `Harga Properti harus valid.`;
          }
        });
    },
    downPayment(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.downPayment.required'))
        .custom(() => {
          if (this.downPaymentValue > this.purchasePriceValue) {
            return `Jumlah DP tidak bisa lebih dari Harga Properti.`;
          }
        });
    },
    interestRate(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.interestRate.required'))
        .custom(() => {
          if (this.interestRate <= 0) {
            return `Suku Bunga harus valid.`;
          }
        });
    },
    tenureInYear(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.tenureInYear.required'))
        .custom(() => {
          if (this.tenureInYear <= 0) {
            return `Jangka Waktu Pinjaman harus valid.`;
          }
        });
    },
  },
  mounted() {
    this.setupCalculator();
  },
  methods: {
    validate: function () {
      this.$store.commit('v2/mortgage/SET_PROPERTY_PRICE', this.purchasePriceValue);
      this.$store.commit('v2/mortgage/SET_DOWN_PAYMENT', this.downPaymentValue);
      this.$store.commit('v2/mortgage/SET_INTEREST_RATE', this.interestRate);
      this.$store.commit('v2/mortgage/SET_INTEREST_RATE_FIXED', this.interestRateFixed);
      this.$store.commit('v2/mortgage/SET_TENURE_IN_YEAR', this.tenureInYear);
      this.$store.commit('v2/mortgage/SET_TENURE_IN_YEAR_FIXED', this.tenureInYearFixed);
      return this.$validate();
    },
    async setupCalculator() {
      await this.$store.dispatch('v2/mortgage/getFormData', {});
      let purchasePrice = this.$route.query.purchase_price;
      this.purchasePrice = purchasePrice ? parseInt(purchasePrice) : 0;

      let downPayment = this.$route.query.down_payment;
      this.downPayment = downPayment
        ? parseInt(downPayment)
        : parseInt(this.purchasePrice * this.defaultDP);

      this.loanAmountValue = this.purchasePrice - this.downPayment;
      this.setupInterestRate();
      let tenureInYear = this.$route.query.tenure_in_year;
      this.tenureInYear = tenureInYear ? parseInt(tenureInYear) : 20;
    },
    onSelectBank() {
      this.program = null;
      this.interestRateFixed = 0;
      this.tenureInYearFixed = 0;
      this.setupInterestRate();
      this.tenureInYear = 20;
    },
    onSelectProgram() {
      if (this.program) {
        this.interestRateFixed = this.program.fixed_interest_rate;
        this.tenureInYearFixed = this.program.fixed_interest_tenor;
      } else {
        this.interestRateFixed = 0;
        this.tenureInYearFixed = 0;
      }
      this.setupInterestRate();
      this.tenureInYear = 20;
    },
    setupInterestRate() {
      if (this.purchasePrice <= 185000000) {
        this.interestRate = 5;
      } else {
        this.interestRate = 12;
      }
    },
    async calculateMortgage() {
      const valid = await this.validate();
      if (valid) {
        await this.$store.dispatch('v2/mortgage/calculateMortgage');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.form--group {
  display: block;
  .val-error {
    margin-top: 8px;
  }
}
.bank--preference {
  background-color: $color-white;
  border-radius: 0.5rem;

  img {
    width: 100%;
    max-height: 50px;
    object-fit: contain;
    margin: auto;
    padding: 0.5rem;
  }

  .custom-checkbox {
    display: flex;
    align-items: center;

    .custom-control-label {
      width: 100%;

      &::before {
        top: calc(50% - 8px);
      }

      &::after {
        top: calc(50% - 8px);
      }
    }
  }

  .bank--name {
    font-size: $font-md;
  }

  .bank--description {
    font-size: $font-sm;
    color: darkgrey;
  }
}

.delete--file {
  color: red;
  cursor: pointer;
}
</style>
